import $ from 'jquery'
import axios from 'axios'

export function getGtimgtockData (stocks) {
  return axios({
    url: `https://qt.gtimg.cn/q=${stocks}`,
    method: 'get'
  })
}

const getSinaStockData = (stocks) => {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: 'https://hq.sinajs.cn/list=' + stocks,
      dataType: 'script',
      cache: 'false',
      type: 'GET',
      success: function () {
        const lists = getWindowValue(stocks.split(','))
        resolve(lists)
      },
      error: function (res) {
        reject(res)
      }
    })
  })
}

function getWindowValue (stocks) {
  const result = []
  const reg = /^hq_str_/
  for (const key of Object.keys(window)) {
    if (!reg.test(key)) continue
    for (const stock of stocks) {
      if (key === ('hq_str_' + stock)) {
        const values = window[key].split(',')
        const item = {
          code: stock,
          name: values[0],
          yesterdayPrice: parseFloat(values[2]),
          currentPrice: parseFloat(values[3]),
          min: parseFloat((values[2] * (stock.startsWith('300', 2) ? 0.8 : 0.9)).toFixed(2)),
          max: parseFloat((values[2] * (stock.startsWith('300', 2) ? 1.2 : 1.1)).toFixed(2)),
          currentDate: values[30] + ' ' + values[31],
          per: ((values[3] - values[2]) * 100 / values[2]).toFixed(2)
        }
        result.push(item)
        break
      }
    }
  }
  return result
}

export { getSinaStockData }
