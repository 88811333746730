<template>
  <div>
    <div ref="myChart" class="chart"></div>
  </div>
</template>

<script>
import 'zrender/lib/svg/svg'

export default {
  name: 'StageSpeedGauge',
  data () {
    return {
      myChartInit: '',
      option: {
        series: [
          {
            type: 'gauge',
            min: this.min,
            max: this.max,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 8,
                color: [
                  [0.5, '#67e0e3'],
                  [1, '#fd666d']
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -8,
              splitNumber: 5,
              length: 4,
              lineStyle: {
                color: '#fff',
                width: 1
              }
            },
            splitLine: {
              distance: -8,
              length: 8,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            axisLabel: {
              color: 'inherit',
              distance: 12,
              fontSize: 8,
              formatter: function (value) {
                return parseFloat(value).toFixed(2)
              }
            },
            detail: {
              valueAnimation: true,
              formatter: function (value) {
                return parseFloat(value).toFixed(2)
              },
              color: 'inherit',
              fontSize: 10
            },
            data: [
              {
                value: this.currentPrice,
                name: this.name
              }
            ],
            title: {
              offsetCenter: [0, '72%'],
              color: 'inherit',
              fontSize: 8
            }
          }
        ]
      }
    }
  },
  mounted () {
    this.initEcharts()
  },
  methods: {
    initEcharts () {
      const myChart = this.$refs.myChart
      this.myChartInit = this.$echarts.init(myChart, 'dark', { renderer: 'svg' })
      this.myChartInit.setOption(this.option)
    }
  },
  props: {
    currentPrice: {
      type: Number,
      default: 50
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    name: {
      type: String,
      default: ''
    }
  },
  watch: {
    currentPrice: function (newV, oldV) {
      this.myChartInit.setOption({
        series: [
          {
            data: [
              {
                value: newV,
                name: this.name
              }
            ]
          }
        ]
      }, false, true)
    }
  }
}
</script>

<style scoped>
.chart {
  width: 9.5rem;
  height: 9.5rem;
}
</style>
