<template>
  <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel" style="width: 9.5rem">
    <div class="carousel-indicators mb-1">
      <button class="" type="button" data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="index" :class="{'active':index==0}" v-for="(item,index) in values" :key="'value' + index"></button>
    </div>
    <div class="carousel-inner" style="width: 9.5rem">
      <div class="carousel-item" v-for="(item,index) in values" :key="'value' + index" :class="{'active':index==0}">
        <stage-speed-gauge :currentPrice="item.currentPrice" :name="item.name" :min="item.min"
                           :max="item.max"
                           class="float-start w-auto m-0 p-0"></stage-speed-gauge>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>
<script>
import StageSpeedGauge from '@/components/echarts/StageSpeedGauge'
import { getGtimgtockData } from '@/common/api/stock'

export default {
  name: 'Stock',
  components: {
    StageSpeedGauge
  },
  data () {
    return {
      values: []
    }
  },
  mounted () {
    this.changeValue()
  },
  methods: {
    changeValue () {
      const stocks = 'sh600872,sh601989,sh600050,sh600498,sh600487,sh601700,sh600871,sh601816,sh601519,sz000039,sz300999,sh600941,sz300708'
      const that = this
      function getList () {
        getGtimgtockData(stocks).then((response) => {
          that.values = []
          let stockList = response.data.split(';')
          stockList = stockList.slice(0, stockList.lastIndexOf(';'))
          for (let i = 0; i < stockList.length; i++) {
            const value = stockList[i].split('~')
            const item = {
              code: value[2],
              name: value[1],
              yesterdayPrice: parseFloat(value[4]),
              currentPrice: parseFloat(value[3]),
              min: parseFloat(value[48]),
              max: parseFloat(value[47]),
              per: parseFloat(value[31])
            }
            that.values.push(item)
          }
        })
        return getList
      }
      // function getList () {
      //   getSinaStockData(stocks).then((stockList) => {
      //     for (let i = 0; i < stockList.length; i++) {
      //       that.values = stockList
      //     }
      //   })
      //   return getList
      // }

      setInterval(getList(), 3000)
    }
  }
}
</script>

<style scoped>

</style>
